<template>
    <div class="bg-white border rounded p-2">
        <div class="d-flex justify-content-between" v-if="dsActualHours.state._isLoaded">
            <h6 class="mb-1 me-2">{{$t("Hours Worked")}}</h6>
        </div>
        <div v-for="(item, index) in dsActualHours.data">
            <span>{{index + 1}})</span>
            <span class="ms-2">{{utils.formatDate(item.FromDateTime,'General Date Short Time') }}</span>
            <span class="mx-2">-</span>
            <span v-if="item.ToDateTime">{{utils.formatDate(item.ToDateTime,'General Date Short Time')}}</span>
            <i v-if="!item.ToDateTime">{{$t("working")}}</i>
            <span class="ms-3" v-if="item.FromDateTime != null && item.ToDateTime != null">({{item.Hours}}h {{item.Minutes}}m)</span>
        </div>
        
    </div>
   
</template>


<script setup>   
    import { ref, defineProps } from 'vue';
    import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts';
    import utils from 'o365.modules.utils.js';
    import OrgunitsLookup from 'o365.vue.components.OrgUnitsLookup.vue';
    import context from 'o365.modules.Context.ts';

    const props = defineProps({
        itemId: Number,
        stepId: Number,
        readOnly:Boolean
    });

    const counter = ref(0);
    const procStartStopWork = getOrCreateProcedure({ id:"procGetRecId", procedureName:"astp_Workflow_ItemsStepsActualHoursStartStop" });
        

    const dsActualHours = getOrCreateDataObject({
        id: 'dsActualHours',
        viewName: 'aviw_Workflow_ItemsStepsActualHours',
        maxRecords: 50,
        uniqueTable: 'atbv_Workflow_ItemsStepsActualHours',
        loadRecents: false,
        distinctRows: false,
        fields:
             [{name: "FromDateTime", type: "number" },
             {name: "ToDateTime", type: "string" },
             {name: "Minutes", type: "number" },
             ]
    });
    
    dsActualHours.recordSource.whereClause = "Step_ID = " + props.stepId;
    dsActualHours.load();


    function isStarted(){
        return dsActualHours.data.filter(x=>{
            return x.FromDateTime != null && x.ToDateTime == null
        }).length > 0;
    }
   
    function startStopWork(){
        procStartStopWork.execute({Step_ID:props.stepId}).then((pData)=>{
            dsActualHours.load();
        });
    }


</script>